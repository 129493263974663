import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import ErrorImage from "./../assets/images/error.png"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error_404_wrapper">
      <div className="error_404">
        <img src={ErrorImage} alt="error" />
        <h1>Уууупс ... </h1>
        <h2>Попаднахте на код 404</h2>
        <p>
          Изглежда, че страницата която търсихте не съществува. Моля проверете
          URL адреса и опитайте отново.
        </p>
        <Link className='back_to_home_btn' to="/">Назад към началната страница</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
